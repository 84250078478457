<template>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-talentboard-learner">
    <!-- main-header -->
    <lxp-main-header :show-breadcrumb="true"
                     :show-custom-title="true"
                     :show-excel-download="false"
                     :title="`Talent Board`"
                     :file-down-key="guideFileKey" >
    </lxp-main-header>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content min-component">
      <!-- content-section:목표설정 -->
      <section v-if="allReadies" class="content-section">
        <TalentGoal
            :works="works"
            :goals="goals"
            :abilities="abilities"
            :aces="aces"
            :license-codes="licenseCodes"
            @completed="getTalentWorks"
        />
        <TalentJourney
            :learns="learns"
            :license-codes="licenseCodes"
            :goals="goals"
        />
      </section>

      <TalentAbility
          v-if="allReadies"
          :works="works"
          :abilities="abilities"
          :aces="aces"
          :all-abilities="allAbilities"
          :license-codes="licenseCodes"
      />

      <!-- content-section:역량개발지수 비교 -->
      <!-- content-section:자기주도학습 지수 -->
      <TalentSSL
          v-if="allReadies"
      />
    </div>
  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import TalentJourney from '@/components/mypage/talent/TalentJourney';
import TalentSSL from '@/components/mypage/talent/TalentSSL';
import TalentAbility from '@/components/mypage/talent/TalentAbility';
import TalentGoal from '@/components/mypage/talent/TalentGoal';
import {talentMainSetup} from '@/assets/js/modules/mypage/talent/talent-setup';

export default {
  name: 'MyTalentBoard',
  components: {TalentGoal, TalentAbility, TalentSSL, TalentJourney, LxpMainHeader},
  setup: talentMainSetup
};
</script>
