<template>
  <div class="mysetting" style="justify-content: flex-start;padding-bottom:0px">
    <span class="text talent-job">
      <span class="text" style="font-weight:bold">현재직무:</span>
      <span class="text" style="margin-left: 10px">{{ jobs[0] && jobs[0].jobNm && jobs[0].jobNm.length > 0 ? jobs[0].jobNm : '-' }}</span>
    </span>
    <span class="text talent-job">
        <span class="text" style="font-weight:bold;margin-left: 10px">추천직무:</span>
        <span class="text" style="margin-left: 10px">{{ jobs[1] && jobs[1].jobNm && jobs[1].jobNm.length > 0 ? jobs[1].jobNm : '-' }}</span>
    </span>
    <span class="text talent-job">
        <span class="text" style="font-weight:bold;margin-left: 10px">관심직무:</span>
        <span class="text" style="margin-left: 10px">{{ jobs[2] && jobs[2].jobNm && jobs[2].jobNm.length > 0 ? jobs[2].jobNm : '-' }}</span>
    </span>
    </div>
    <div class="mysetting" style="justify-content: flex-start;padding:0px;">
    <span class="text talent-job" >
      <span class="text" style="font-weight:bold;">커리어플랜 1단계:</span>
      <span class="text" style="margin-left: 10px">{{ cdps && cdps[0] && cdps[0].cdp1 ? cdps[0].cdp1 : '-' }}</span>
    </span>
    <span class="text talent-job">
        <span class="text" style="font-weight:bold;margin-left: 10px">커리어플랜 2단계:</span>
        <span class="text" style="margin-left: 10px">{{ cdps && cdps[0] && cdps[0].cdp2 ? cdps[0].cdp2 : '-' }}</span>
    </span>
    <span class="text talent-job">
        <span class="text" style="font-weight:bold;margin-left: 10px">커리어플랜 3단계:</span>
        <span class="text" style="margin-left: 10px">{{ cdps && cdps[0] && cdps[0].cdp3 ? cdps[0].cdp3 : '-' }}</span>
    </span>
    </div>
  <div class="mysetting" style="padding-top: 0px;">
      <button class="text talent-job" style="width: 210px;margin-left: 10px" @click="goRecommendSystem">
          <span class="text text-certificate" style="color: red">인재추천시스템</span>
      </button>

      <button class="kb-btn-setting" @click="goalModal=!goalModal"><i class="icon-goal"></i>
        <span class="text">목표설정</span>
      </button>

    <div class="tool-tip" :class="{'is-active': tooltip}">
      <button class="tool-tip-button" @click="tooltip = !tooltip"><img src="@/assets/lxp/images/common/ic_tooltip.svg" alt=""></button>
      <div class="tool-tip-desc is-right">
        KB인으로서의 역할 수행을 위한 준비수준을 확인하고, 역량개발을 위한 학습목표를 설정합니다.
      </div>
    </div>

  </div>

  <div v-if="goals.length === 0" class="content-section-card none-contents">
    <p class="text">현재 설정된 목표가 없어요 <br>KB인으로서의 당신만의 목표를 설정해주세요.</p>
  </div>

  <!-- content-section-card:내 목표, 내 현황 -->
  <div v-else class="content-section-card card-major">
    <!-- content-section-card > card-goal -->
    <article class="card-column card-goal">
      <header class="card-header">
        <div class="header-title">
          <p class="subtitle">내 학습목표</p>
<!--          <h3 class="title">{{ goalTalent.name }} : {{ goalTalent.text }}</h3>-->
          <h3 class="title">{{ goalTalent.text }}</h3>
        </div>
        <div class="header-side">
          <span class="subtext">{{ restDay > -1 ? `목표기한까지 ${restDay}일` : '-' }}</span>
        </div>
      </header>
      <div class="level-contents">

        <div class="certificate-container">
          <template v-if="hopeLicenses.length > 0">
            <h4 class="certificate-title">취득희망자격증</h4>
            <ul class="certificate-list">
              <li v-for="(hopeLicense, index) in hopeLicenses" class="certificate" :class="[isLicenseGet(hopeLicense) && 'is-active']" :key="index"><span class="text">{{getLicenseNm(hopeLicense)}}</span></li>
            </ul>
          </template>
        </div>


        <div class="running-level-container" :data-level="talentLevel">
          <div class="level-bar"><i class="icon-running"></i></div>
          <ul class="level-steps">
            <li class="level-step step-active">
              <div class="step-dot"></div>
              <div class="step-content"><span class="title text-center">초급<br>(Beginner)</span></div>
            </li>
            <li class="level-step">
              <div class="step-dot"></div>
              <div class="step-content"><span class="title text-center">중급<br>(Intermediate)</span></div>
            </li>
            <li class="level-step">
              <div class="step-dot"></div>
              <div class="step-content"><span class="title text-center">고급<br>(Advanced)</span></div>
            </li>
            <li class="level-step step-goal">
              <div class="step-dot"></div>
              <div class="step-content"><div class="badge"><span class="badge-text">목표</span></div><span class="title text-center">전문가<br>(Expert)</span></div>
            </li>
          </ul>
        </div>

      </div>
    </article>
    <!-- content-section-card > card-my -->
    <article class="card-column card-my">
      <header class="card-header">
        <div class="header-title">
          <h3 class="title">{{ session.lrnerNm }} <em class="sm">{{ session.jbgdCd }}</em></h3>
        </div>
      </header>
      <div class="my-contents">
        <div class="my-meta">
          <div class="meta"><h4 class="title">현재 학습분야</h4><span class="text">{{ getCrseWorkNm(nowGoal.crseWorkDtlSn, works) }}</span></div>
          <div class="meta" style="margin-left: 40px"><h4 class="title">우수 학습분야</h4><span class="text">{{ getCrseWorkNm(bestAbility.crseWorkDtlSn, works) }}</span></div>
          <div class="meta" style="margin-left: 40px"><h4 class="title">희망 학습분야</h4><span class="text">{{ getCrseWorkNm(hopeGoal.crseWorkDtlSn, works) }}</span></div>
        </div>
        <div class="my-feedback">
          <div class="feed-list">
            <!-- feed -->
            <div v-if="hopeGoal.crseWorkDtlSn > 0" class="feed">
              <div class="feed-avatar">
                <ProfileImg
                    :target="session"
                />
              </div>
              <div class="feed-content">
                <p class="feed-text"><a href="javascript:" class="feed-link">{{ hopeGoal.goalsCn ? hopeGoal.goalsCn : '-' }}</a></p>
                <div class="feed-meta">
                  <strong class="sender">{{ session.lrnerNm }}</strong>
                  <span class="date">{{ timestampToDateFormat(hopeGoal.mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span>
                </div>
              </div>
            </div>
            <!-- feed -->
            <div v-if="coachings.length > 0" class="feed coaching">
              <div class="feed-avatar">
                <ProfileImg
                    :target="{lrnerId: coachings[0].mdfrId}"
                />
              </div>
              <div class="feed-content">
                <p class="feed-text"><a href="javascript:" class="feed-link">{{ coachings[0].goalsCn }}</a></p>
                <div class="feed-meta"><strong class="sender">{{ coachings[0].mdfrNm }}</strong><span class="date">{{ timestampToDateFormat(coachings[0].mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span></div>
              </div>
            </div>
          </div>

          <div class="kb-btn kb-btn-secondary kb-btn-wide kb-btn-feed" @click="coachingModal = true"><span class="text">코칭 전체보기</span></div>
        </div>
      </div>
    </article>
  </div>

  <TalentGoalModal
      v-if="goalModal"
      v-model:toggle="goalModal"
      :works="works"
      :now-goal="nowGoal"
      :hope-goal="hopeGoal"
      :abilities="abilities"
      :aces="aces"
      :license-codes="licenseCodes"
      :jobs="jobs"
      :cdps="cdps"
      :goRecommendSystem="goRecommendSystem"
      @completed="completeSetting"
  />

  <TalentCoachingModal
      v-if="coachingModal"
      v-model:toggle="coachingModal"
      :hope="hopeGoal"
      :coachings="coachings"
  />

</template>

<script>

import ProfileImg from '@/components/common/ProfileImg';
import TalentGoalModal from '@/components/mypage/talent/TalentGoalModal';
import TalentCoachingModal from '@/components/mypage/talent/TalentCoachingModal';
import {talentGoalSetup} from '@/assets/js/modules/mypage/talent/talent-setup';

export default {
  name: "TalentGoal",
  components: {
    TalentCoachingModal,
    TalentGoalModal,
    ProfileImg,
  },
  props: {
    works: Array,
    goals: Array,
    abilities: Array,
    aces: Array,
    licenseCodes: Array
  },
  emits: ['completed'],
  setup: talentGoalSetup
}
</script>
<style>
  .talent-job {
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .text-certificate {
    display: inline-flex;
    height: 40px;
    align-items: center;
    padding: 0 16px;
    border-radius: 20px;
    box-shadow: inset 0 0 0 1px var(--kb-light-silver);
    margin-right: 8px;
    font: normal 14px/18px var(--kb-font-KBFGTextB);
    background: #fffae9 !important;
  }
</style>
