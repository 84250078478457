<template>
  <!-- [begin::popup-container] -->
  <div class="popup-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-myclass-o014" @click="closeToggles(toggles)">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <div>
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">역량개발 학습목표 설정</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content">
            <!-- popup-section:목표설정 -->
            <section class="popup-section">
              <header class="section-header"><h4 class="title">목표설정</h4></header>
              <div class="kb-form-fields">
                <!-- kb-form-row:직무정보 임시-->
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label" style="display: inline-flex;align-items: center;">
                      <span class="kb-form-label-text">직무정보</span>
                    </label>
                  </div>
                  <div class="kb-form-column" style="display: inline-flex;align-items: center;">
                    <span class="text" style="display: flex;align-items: center">
                      <span class="text" style="font-weight:bold;width: 72px;">현재직무</span>
                      <span class="text" style="margin-left: 10px;width: 176px">{{ jobs[0] && jobs[0].jobNm && jobs[0].jobNm.length > 0 ? jobs[0].jobNm : '-' }}-</span>&nbsp;&nbsp;
                    </span>
                    <span class="text" style="display: flex;align-items: center">
                      <span class="text" style="font-weight:bold;width: 72px;">추천직무</span>
                      <span class="text"  style="margin-left: 10px;width: 176px">{{ jobs[1] && jobs[1].jobNm && jobs[1].jobNm.length > 0 ? jobs[1].jobNm : '-' }}</span>&nbsp;&nbsp;
                    </span>
                    <span class="text" style="display: flex;align-items: center">
                      <span class="text" style="font-weight:bold;width: 72px;">관심직무</span>
                      <span class="text" style="width: 176px">&nbsp;{{ jobs[2] && jobs[2].jobNm && jobs[2].jobNm.length > 0 ? jobs[2].jobNm : '-' }}</span>&nbsp;&nbsp;

                    </span>
                    <button class="text text-certificate" style="width: 120px;color: red;" @click="goRecommendSystem">인재추천시스템</button>
                  </div>
                </div>

                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label" style="display: inline-flex;align-items: center;">
                      <span class="kb-form-label-text">커리어플랜정보</span>
                    </label>
                  </div>
                  <div class="kb-form-column" style="display: inline-flex;align-items: center;">
                    <span class="text">
                      <span class="text" style="font-weight:bold">커리어플랜 1단계</span>
                      <span class="text">&nbsp;{{ cdps && cdps[0] && cdps[0].cdp1 ? cdps[0].cdp1 : '-' }}</span>&nbsp;&nbsp;
                    </span>
                    <span class="text">
                      <span class="text" style="font-weight:bold;margin-left: 8px">커리어플랜 2단계</span>
                      <span class="text">&nbsp;{{ cdps && cdps[0] && cdps[0].cdp2 ? cdps[0].cdp2 : '-' }}</span>&nbsp;&nbsp;
                    </span>
                    <span class="text">
                      <span class="text" style="font-weight:bold;margin-left: 8px">커리어플랜 3단계</span>
                      <span class="text">&nbsp;{{ cdps && cdps[0] && cdps[0].cdp3 ? cdps[0].cdp3 : '-' }}</span>&nbsp;&nbsp;
                    </span>
                  </div>
                </div>

                <!-- kb-form-row:현재역할 -->
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">현재 학습분야</span>
                    </label>
                  </div>
                  <div class="kb-form-column column-dropdown">
                    <SortButtonSelect
                        v-model="params.now"
                        v-model:toggle="toggles.now"
                        title="현재 학습분야"
                        :options="works"
                        sequence-key="crseWorkDtlSn"
                        name-key="crseWorkDtlNm"
                        :is-num="true"
                        @update:toggle="closeToggles(toggles, 'now')"
                    />
                  </div>
                </div>
                <!-- kb-form-row:희망역할 -->
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">희망 학습분야</span>
                    </label>
                  </div>
                  <div class="kb-form-column column-dropdown">
                    <SortButtonSelect
                        v-model="params.hope"
                        v-model:toggle="toggles.hope"
                        title="희망 학습분야"
                        :options="works"
                        sequence-key="crseWorkDtlSn"
                        name-key="crseWorkDtlNm"
                        :is-num="true"
                        @update:toggle="closeToggles(toggles, 'hope')"
                    />
                  </div>
                </div>
                <!-- kb-form-row:목표기한 -->
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">목표기한</span>
                    </label>
                  </div>
                  <div class="kb-form-column column-dropdown">
                    <TalentGoalCalendar
                        v-model="goalDate"
                        v-model:toggle="toggles.date"
                        :width="400"
                    />
                  </div>
                </div>
                <!-- kb-form-row:목표레벨 -->
                <div class="kb-form-row">
                  <div class="kb-form-column">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">목표레벨</span>
                    </label>
                    <!-- 목표 달성시 data-level="4" -->
                    <div class="running-level-container" :data-level="params.hope > 0 ? 4 : 0">
                      <div class="level-bar"><i class="icon-running"></i></div>
                      <ul class="level-steps">
                        <li class="level-step">
                          <div class="step-dot"></div>
                          <div class="step-content">
                            <span class="title">초급<br>(Beginner)</span>
                          </div>
                        </li>
                        <li class="level-step">
                          <div class="step-dot"></div>
                          <div class="step-content">
                            <label class="title">중급<br>(Intermediate)</label>
<!--                            <div class="kb-form-check"><input type="radio" id="chk_level_2" name="chk_level" class="kb-form-check-input" /></div>-->
                          </div>
                        </li>
                        <li class="level-step">
                          <div class="step-dot"></div>
                          <div class="step-content">
                            <label class="title">고급<br>(Advanced)</label>
<!--                            <div class="kb-form-check"><input type="radio" id="chk_level_3" name="chk_level" class="kb-form-check-input" /></div>-->
                          </div>
                        </li>
                        <li class="level-step step-goal">
                          <div class="step-dot"></div>
                          <div class="step-content">
                            <label class="title">전문가<br>(Expert)</label>
                            <!-- 목표 달성시 사라지고 -->
<!--                            <span class="text">이미 최대 레벨에 도달하셨습니다</span>-->
<!--                             <div v-if="talentLevel < 4" class="kb-form-check"><input type="radio" id="chk_level_4" name="chk_level" class="kb-form-check-input" checked readonly/></div>-->
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- kb-form-row:목표 -->
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">목표</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <textarea v-model.trim="params.goalsCn" cols="30" rows="4" placeholder="목표를 입력하세요" class="kb-form-control"></textarea>
                    <div class="textarea-bottom divider">
                      <div class="bottom-column">
                        <span class="counter">{{ goalsCnCount }} / {{ maxLimitText }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="popup-section">
              <header class="section-header"><h4 class="title">취득희망 자격증</h4><div class="header-sub"><strong class="text text-muted">취득 희망 자격증 최대 5개 선택 가능</strong></div></header>

              <div class="kb-form-search mb-3" style="justify-content: end;">
                <div class="kb-form-search-field">
                  <input v-model.trim="filters.keyword" type="text" class="kb-form-search-input" placeholder="자격증 검색하세요" @keyup.enter="getLicenseList(1)">
                  <button class="kb-form-search-btn" @click="getLicenseList(1)"><i class="icon-search">검색</i></button>
                </div>
              </div>

              <div class="kb-table kb-table-striped">
                <table>
                  <colgroup>
                    <col />
                    <col style="width:180px;" />
                    <col style="width:180px;" />
                    <col style="width:180px;" />
                    <col style="width:160px;" />
                    <col style="width:140px" />
                  </colgroup>
                  <thead>
                  <tr>
                    <th><span class="th-title">자격증명</span></th>
                    <th><span class="th-title">주관처</span></th>
                    <th><span class="th-title">자격증코드</span></th>
                    <th><span class="th-title">등급</span></th>
                    <th><span class="th-title">취득여부</span></th>
                    <th><span class="th-title text-primary">선택</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-if="goalLicenses && goalLicenses.length > 0">
                    <tr v-for="(license, index) in goalLicenses" :key="index">
                      <td><strong class="td-text">{{ license.lcsnNm }}</strong></td>
                      <td><span class="td-text">{{ license.pblcnInstNm }}</span></td>
                      <td><span class="td-text">{{ license.lcsnCd }}</span></td>
                      <td><span class="td-text">{{ license.grd1Cd }}</span></td>
                      <td>
                        <span class="td-text">{{ isLicenseGet(license.lcsnCd) ? '취득' : '미취득' }}</span>
                      </td>
                      <td>
                        <div class="td-cell">
                          <span v-if="isDisabledLicense(license.lcsnCd)">선택불가</span>
                          <template v-else>
                            <div v-if="!isLicenseGet(license.lcsnCd)" class="kb-form-check">
                              <input v-model="selected" type="checkbox" class="kb-form-check-input" :id="`chk_list_${license.lcsnCd}`" name="chk_list" :value="license.lcsnCd" @change="checkLicense($event, license.lcsnCd)"/>
                            </div>
                          </template>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <tr v-else >
                    <td colspan="6" style="text-align: center">검색결과가 없습니다.</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <CommonPaginationFront
                  :paging="paging" :page-func="getLicenseList"
              />
            </section>

          </div>
          <!-- popup-inner > popup-buttons -->
          <div class="popup-buttons">
            <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">취소</span></button>
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickSave"><span class="text">저장</span></button>
          </div>
        </div>
      </div>
      <!-- popup > popup-close -->
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
    <!-- //popup -->
  </div>
  <!-- //[begin::popup-container] -->
</template>

<script>
import {computed, reactive, ref} from 'vue';
import TalentGoalCalendar from '@/components/mypage/talent/TalentGoalCalendar';
import SortButtonSelect from '@/components/common/SortButtonSelect';
import {closeToggles, datePartToFormat, getListFunc} from '@/assets/js/ui.init';
import {ACT_GET_LCSN_GOAL_LIST} from '@/store/modules/license/license';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {useAlert} from '@/assets/js/modules/common/alert';
import {useStore} from 'vuex';
import {ACT_INSERT_LRN_CRSE_WORK_GOALS, ACT_UPDATE_LRN_CRSE_WORK_GOALS} from '@/store/modules/talent/talent';
import {isSuccess} from '@/assets/js/util';
import {getTargetLevel} from '@/assets/js/modules/mypage/talent-common';

export default {
  name: 'TalentGoalModal',
  components: {CommonPaginationFront, SortButtonSelect, TalentGoalCalendar},
  props: {
    toggle: Boolean,
    works: Array,

    nowGoal: Object,
    hopeGoal: Object,

    abilities: Array,
    aces: Array,

    licenseCodes: Array,
    jobs: Array,
    cdps: Array,
    goRecommendSystem:Function
  },
  emits: ['update:toggle', 'completed'],
  setup(props, {emit}){

    const store = useStore();
    const {showMessage, showConfirm} = useAlert();

    const session = computed(() => store.state.auth.session);

    const maxLimitText = 500;
    const licenseLimit = 5;
    const disabledLicenses = ['WR96'];

    const params = reactive({
      now: props.nowGoal.crseWorkDtlSn,
      hope: props.hopeGoal.crseWorkDtlSn,
      goalsEndYmd: '',
      goalsCn: props.hopeGoal.goalsCn,
      lcsnLst: props.hopeGoal.lcsnLst || '',
    });

    const toggles = reactive({
      works: false,
      now: false,
      hope: false,
      date: false,
    });

    const talentLevel = computed(() => getTargetLevel(props.works, props.abilities, props.abilities, props.licenseCodes, params.hope));
    const goalLicenses = ref([]);
    const selected = ref([]);

    if(params.lcsnLst && params.lcsnLst.length > 0){
      selected.value = params.lcsnLst.split(',');
    }

    // const licenseCodes = computed(() => props.licenses.map(x => x.lcsnCd))

    const filters = reactive({
      keyword: ''
    });

    const paging = reactive({
      pageNo: 0,
      pageSize: 5,
      totalCount: 0
    });

    const goalDate = ref(null);
    if(props.hopeGoal.crseWorkDtlSn > 0 && props.hopeGoal.goalsEndYmd){
      const split = props.hopeGoal.goalsEndYmd.split('-');
      if(split.length === 3){
        const dateSplit = split.map(x => parseInt(x));
        goalDate.value = {
          year: dateSplit[0],
          month: dateSplit[1],
          day: dateSplit[2]
        }
      }
    }

    const goalsCnCount = computed(() => {
      if(params.goalsCn){
        return params.goalsCn.length;
      }
      return 0;
    });

    const getLicenseList = (pageNo) => {
      paging.pageNo = pageNo ? pageNo : 1;
      getListFunc(`license/${ACT_GET_LCSN_GOAL_LIST}`, {sortCd: 'g', pageNo: paging.pageNo, pageSize: paging.pageSize, ...filters}, goalLicenses, paging, () => {});
    }

    const isLicenseGet = (code) => {
      return props.licenseCodes.includes(code);
    }

    const isDisabledLicense = (code) => {
      return disabledLicenses.includes(code);
    }



    const checkLicense = (e, code) => {
      // length 체크
      if(e.target.checked){
        // 5보다 클경우
        if(selected.value.length > licenseLimit){
          if(selected.value.filter(x => !props.licenseCodes.includes(x)).length > licenseLimit){
            selected.value.splice(selected.value.indexOf(code), 1);
            showMessage('취득희망 자격증은 최대 5개만 선택할 수 있습니다.');
          }
        }
      }
    }

    const closeModal = () => {
      emit('update:toggle', false);
    };

    const validateParams = () => {
      let result = false;
      let msg = '';

      if(params.now === 0){
        msg = '현재 역할을 선택해주세요.'
      }else if(params.hope === 0){
        msg = '희망 역할을 선택해주세요.'
      }else if(params.now === params.hope){
        msg = '현재 역할과 희망역할은 중복될 수 없습니다.'
      }else if(!goalDate.value){
        msg = '목표 기한을 설정해주세요.'
      }else if(!goalsCnCount.value){
        msg = '목표를 입력해주세요.'
      }else if(goalsCnCount.value > maxLimitText) {
        msg = `목표는 최대 ${maxLimitText}까지 작성 가능합니다.`
      }
      else{
        result = true;
      }

      if(!result) showMessage(msg);

      return result;
    };

    const isLoading = ref(false);

    const saveGoals = () => {
      // 현재 따로, 취득 따로.
      const nowParams = {
        goalsDivCd: '00',
        crseWorkDtlSn: params.now
      };
      const hopeParams = {
        goalsDivCd: '01',
        crseWorkDtlSn: params.hope,
        goalsEndYmd: datePartToFormat(goalDate.value, '-'),
        goalsCn: params.goalsCn,
      };

      const selectedLicense = selected.value.filter(x => !props.licenseCodes.includes(x));
      hopeParams.lcsnLst = selectedLicense.length > 0 ? selectedLicense.join(',') : '';

      const nowSave = props.nowGoal.crseWorkGoalsSn > 0 ?
          store.dispatch(`talent/${ACT_UPDATE_LRN_CRSE_WORK_GOALS}`, {crseWorkGoalsSn: props.nowGoal.crseWorkGoalsSn, params: nowParams}) :
          store.dispatch(`talent/${ACT_INSERT_LRN_CRSE_WORK_GOALS}`, nowParams);

      const hopeSave = props.hopeGoal.crseWorkGoalsSn > 0 ?
          store.dispatch(`talent/${ACT_UPDATE_LRN_CRSE_WORK_GOALS}`, {crseWorkGoalsSn: props.hopeGoal.crseWorkGoalsSn, params: hopeParams}) :
          store.dispatch(`talent/${ACT_INSERT_LRN_CRSE_WORK_GOALS}`, hopeParams);

      Promise.all([
        nowSave, hopeSave
      ])
      .then(([nRes, hRes]) => {
        isLoading.value = false;
        if(isSuccess(nRes) && isSuccess(hRes)){
          showMessage('역량개발 목표가 설정되었습니다.');
          emit('completed');
          closeModal();
        }else{
          showMessage('오류가 발생했습니다. 다시 시도해주세요.');
        }
      })
      .catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 다시 시도해주세요.');
      });
    }

    const clickSave = () => {
      if(isLoading.value) return;

      if(validateParams()){
        isLoading.value = true;
        showConfirm({
          title: '목표 설정',
          text: '역량개발 목표를 설정하시겠습니까?',
          callback: saveGoals,
          closeCallback: () => {
            isLoading.value = false;
          },

        });
      }
    }

    getLicenseList();

    return {
      maxLimitText,
      params,
      toggles,
      goalDate,
      goalLicenses,
      talentLevel,
      goalsCnCount,
      selected,
      paging,
      session,
      filters,
      clickSave,
      getLicenseList,
      isLicenseGet,
      isDisabledLicense,
      checkLicense,
      closeModal,
      closeToggles
    }
  }
};
</script>
