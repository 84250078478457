<template>
  <div class="popup-container is-active">
    <!-- popup -->
    <div class="popup" id="popup-talentboard-o018">
      <!-- popup > popup-inner -->
      <div class="popup-inner">
        <form action="">
          <!-- popup-inner > popup-header -->
          <header class="popup-header">
            <h3 class="title">코칭 전체보기</h3>
          </header>
          <!-- popup-inner > popup-content -->
          <div class="popup-content agree-wrap">
            <div class="coaching-container">
              <div class="feed-list">
                <!-- feed -->
                <div class="feed">
                  <div class="feed-avatar">
                    <ProfileImg
                        :target="{lrnerId: hope.mdfrId}"
                    />
                  </div>
                  <div class="feed-content">
                    <p class="feed-text">{{ hope.goalsCn }}</p>
                    <div class="feed-meta"><strong class="sender">{{ hope.mdfrNm }}(나)</strong><span class="date">{{ timestampToDateFormat(hope.mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span></div>
                  </div>
                </div>
                <!-- feed -->
                <div v-for="(coaching, index) in coachings" class="feed coaching" :key="index">
                  <div class="feed-avatar">
                    <ProfileImg
                        :target="{lrnerId: coaching.mdfrId}"
                    />
                  </div>
                  <div class="feed-content">
                    <p class="feed-text">{{ coaching.goalsCn }}</p>
                    <div class="feed-meta"><strong class="sender">{{ coaching.mdfrNm }}({{ coaching.mdfrId }})</strong><span class="date">{{ timestampToDateFormat(hope.mdfcnDt, 'yyyy.MM.dd hh:mm') }}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {timestampToDateFormat} from '@/assets/js/util';
import ProfileImg from '@/components/common/ProfileImg';

export default {
  name: "TalentCoachingModal",
  components: {ProfileImg},
  props: {
    toggle: Boolean,
    hope: Object,
    coachings: Array
  },
  emits: ['update:toggle'],
  setup(props, {emit}) {
    const closeModal = () => {
      emit('update:toggle', false);
    }

    return {
      closeModal,
      timestampToDateFormat
    }


  }
}
</script>
