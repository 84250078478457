<template>
  <article class="content-section section-divider board-self-scales">
    <header class="section-header">
      <h3 class="title">
        자기주도학습 지수
        <button class="tool-tip-button" @click="tooltip = !tooltip"><img src="@/assets/lxp/images/common/ic_tooltip.svg" alt=""></button>
      </h3>
      <div class="tool-tip" :class="{'is-active': tooltip}">
        <div class="tool-tip-desc is-left" style="left:135px">
          연간 자기주도 학습의 노력 수준을 점수화하여 표시합니다. <br>
          ❶직무&amp;직무외 HRD클라우드 마일리지와 ❷SSL프로그램 학습활동의 합계로 구성됩니다. 나의 수준과 동일직급평균, 전체평균 비교가 가능합니다.
        </div>
      </div>
      <div class="header-column header-util" :class="{'lxp-layout d-none' : isTemp2024Ready}">
        <h3>2024년 자기주도학습 지표 준비중입니다.</h3>
      </div>
      <div class="header-column header-util" :class="{'lxp-layout d-none' : !isTemp2024Ready}">
        <span class="title">{{filters.year}}년</span>
<!--        <div class="sort">-->
<!--          <SortButtonSelect-->
<!--              v-if="yearOptions.length > 1"-->
<!--              v-model="filters.year"-->
<!--              v-model:toggle="toggles.year"-->
<!--              title="년도"-->
<!--              :options="yearOptions"-->
<!--              sequence-key="value"-->
<!--              name-key="display"-->
<!--              :is-num="true"-->
<!--              @selected="selectedYear"-->
<!--          />-->
<!--          <div class="kb-form-dropdown">-->
<!--            <button class="kb-form-dropdown-btn" style="cursor: unset">-->
<!--              <span class="text">{{filters.year}}년</span>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </header>
    <div class="chart-group-container" :class="{'lxp-layout d-none' : !isTemp2024Ready}">
      <!-- chart-multiple -->
      <div class="chart-card chart-multiple">
        <div v-if="sslReadies.ssl && sslReadies.group && sslReadies.hrd" class="chart">
          <ToastNestedPieChart
              :categories="['전체평균', '동일직급평균','나의지수']"
              :series="grapeStatus"
          />
<!--          <img src="../../../assets/lxp/images/@tmp/@tmp_talentboard_chart_03.png" alt="차트 임시이미지" />-->
        </div>
        <div class="chart-content">
          <h4 class="chart-title">자기주도학습 현황</h4>
          <div class="chart-legends">
            <div class="legend"><span class="marker marker-yellow"></span><span class="text">나의지수</span></div>
            <div class="legend"><span class="marker marker-darkbrown"></span><span class="text">동일직급평균</span></div>
            <div class="legend"><span class="marker marker-silver"></span><span class="text">전체평균</span></div>
          </div>
        </div>
      </div>
      <!-- chart-multiple -->
      <div class="chart-card chart-radar">
        <div v-if="sslReadies.ssl  && sslReadies.group " class="chart">
          <ToastRadarChart
              :categories="['가계여신', '기업여신/외환', '자산관리/퇴직연금', 'ICT', '그룹러닝']"
              :series="[
                {
                  name: '참여직원 전체평균',
                  data: grape.total,
                },
                {
                  name: '나의학습현황',
                  data: grape.mine,
                },

              ]"
              :options="sslOptions"
          />

        </div>
        <div class="chart-content">
          <h4 class="chart-title">SSL 학습활동 (학습노트 작성수 기준)</h4>
          <div class="chart-legends">
            <div class="legend"><span class="marker marker-yellow"></span><span class="text">나의학습현황</span></div>
            <div class="legend"><span class="marker marker-gray91"></span><span class="text">참여직원 전체평균</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="kb-table-group-container" :class="{' d-none' : !isTemp2024Ready}">
      <div class="kb-table kb-table-bordered-v2 kb-table-bordered-v2-min">
        <table>
          <colgroup>
            <col style="width:102px;" />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
<!--            <col />-->
<!--            <col />-->
          </colgroup>
          <thead>
          <tr>
            <th class="px-0" rowspan="3"><span class="th-title">구분</span></th>
            <th rowspan="2" colspan="2"><span class="th-title">HRD클라우드 <br/>마일리지<br>(1일 최대 2마일리지)</span></th>
            <th colspan="7"><span class="th-title">SSL 프로그램 학습활동</span></th>
<!--            <th rowspan="3"><span class="th-title">러닝라이브 <br/>연수<br/> 마일리지</span></th>-->
            <th class="px-2" rowspan="3"><span class="th-title">합계</span></th>
          </tr>
          <tr>
            <th colspan="5" class="cell-border"><span class="th-title">학습노트 작성 <br/>(건당 5점)</span></th>
            <th rowspan="2"><span class="th-title">그룹러닝 <br/>학습모임<br>(회당 5점)</span></th>
<!--            <th class="px-2" rowspan="2"><span class="th-title">지식e <br/>진단평가<br>(60점 이상<br>1회 10점)</span></th>-->
            <th class="px-2" rowspan="2"><span class="th-title">SSL 소계</span></th>
          </tr>
          <tr>
            <th class="cell-border"><span class="th-title">동영상</span></th>
            <th class="text-center"><span class="th-title">전산실습</span></th>
            <th><span class="th-title">가계여신</span></th>
            <th><span class="th-title">기업여신/<br>외환</span></th>
            <th><span class="th-title">자산관리/<br>퇴직연금</span></th>
            <th><span class="th-title">ICT</span></th>
            <th><span class="th-title">그룹러닝</span></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="px-0" ><span class="td-text">연간누적지수</span></td>
            <td><span class="td-text">{{ onMlg.sumMlgValue }}M</span></td>
            <td><span class="td-text">{{ offMlg.sumMlgValue }}M</span></td>

            <template v-for="(value, key, index) in targetSSLCodes" :key="index">
              <td v-if="key.includes('self') || key ==='group'">
                <span class="td-text" v-html="getScore(value, key)"></span>
              </td>
            </template>
            <td><span class="td-text" v-html="(groups.length > 0 ? `${groups[0].learnCnt}회<br>(${groups[0].learnCnt * 5}점)` : '0회')"></span></td>
<!--            <td><span class="td-text" v-html="(knowledges.length > 0 ? `${knowledges[0].learnCnt}회<br>(${knowledges[0].sumGetValue}점)` : '0회')"></span></td>-->
<!--            {{getGljisikScore()}}-->
<!--            <td>-->
<!--              <template v-if="sgGroupMCnt > 0">-->
<!--              <span class="td-text">-->
<!--                {{ sgGroupMCnt }}회<br>({{ sgGroupMCnt*5 }}점)-->
<!--              </span>-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                <span class="td-text">-->
<!--                  0회<br>(0점)-->
<!--                </span>-->
<!--              </template>-->
<!--            </td>-->
<!--            <td><span class="td-text">{{jisikECnt}}회<br/>({{jisikECnt > 0 ? jisikECnt*10 : 0}}점)</span></td>-->
            <td><span class="td-text">{{ sslTotalScore }}점</span></td>
<!--            <td><span class="td-text">-</span></td>-->
            <td><span class="td-text">{{ totalScore }}점</span></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </article>
</template>
<script>

import ToastNestedPieChart from '@/components/chart/ToastNestedPieChart';
import ToastRadarChart from '@/components/chart/ToastRadarChart';
// import SortButtonSelect from '@/components/common/SortButtonSelect';
import {talentSSLSetup} from '@/assets/js/modules/mypage/talent/talent-setup';

export default {
  name: "TalentSSL",
  components: { ToastRadarChart, ToastNestedPieChart},
  setup: talentSSLSetup
}
</script>
